import styles from './styles.module.css';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { MainImage } from 'src/components/mainImage';
import { ReadMore } from 'src/components/readMore';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import {
	defaultRichTextOptions,
	getRichText,
} from 'src/templates/utils/richTextOptions/defaultOptions';
import { curatedTitle } from 'src/utils/curated-title';
import {
	defaultAboutPageSections,
	PrivateCollapsiblePanelSection,
	getHrImageUrl,
	BackEndContentTypes,
} from 'src/utils/common';
import { LinkHelper } from 'src/utils/link-helper';
import {
	ContentfulCollapsiblePanelSection,
	Maybe,
	ContentfulAboutPage,
	ContentfulArticleResource,
} from 'src/graphql-types';
import {
	SectionContainer,
	SectionContainerTitle,
	SectionContainerGrid,
} from 'src/components/sectionContainer';
import StaffContactsGraphql from './staff-contacts.graphql';
import GovernanceArticlesGraphql from './governance-articles.graphql';
import { useQuery } from '@apollo/react-hooks';
import { useLoginManager } from 'src/utils/login-manager';
import { CollapsiblePanelCollection } from '../collapsiblePanel/collapsiblePanelCollection';

interface ModifiedContentfulAboutPage extends Omit<ContentfulAboutPage, 'collapsiblePanels'> {
	collapsiblePanels?: Maybe<ContentfulCollapsiblePanelSection | PrivateCollapsiblePanelSection>[];
}

interface PageContext {
	pageContext: {
		data: ModifiedContentfulAboutPage;
	};
}

interface StaffContactsQueryResult {
	contentfulStaffContacts: {
		contentful_id?: string;
		name?: string;
		email?: string;
		phone?: string;
		fax?: string;
		responsibilities?: string[];
	}[];
}

interface GovernanceArticlesProps {
	corporateGovernanceHeading?: string | null;
	corporateGovernanceArticles: Maybe<ContentfulArticleResource>[];
	pageContentfulId: string;
}

interface GovernanceArticlesQueryResult {
	contentfulPrivateGovernanceArticles: {
		contentful_id?: string;
		thumbnailLink?: {
			title?: string;
			description?: string;
		};
		externalArticleUrl?: string;
		internal: {
			type: BackEndContentTypes;
		};
	}[];
}

const GovernanceArticles = (props: GovernanceArticlesProps) => {
	const loginManager = useLoginManager();
	const { corporateGovernanceHeading, corporateGovernanceArticles, pageContentfulId } = props;
	const publicArticles = corporateGovernanceArticles || [];
	const { data, refetch } = useQuery<GovernanceArticlesQueryResult>(GovernanceArticlesGraphql, {
		variables: {
			id: pageContentfulId,
		},
	});
	useEffect(() => {
		if (loginManager.isLoggedIn) {
			refetch();
		}
	}, [loginManager.isLoggedIn]);

	const privateArticles = data?.contentfulPrivateGovernanceArticles || [];

	const allArticles = [...publicArticles, ...privateArticles];

	return (
		<SectionContainer isGrey>
			<SectionContainerTitle
				text={corporateGovernanceHeading || defaultAboutPageSections.corporateGovernance}
				id={LinkHelper.parseInternalLink(
					corporateGovernanceHeading || defaultAboutPageSections.corporateGovernance
				)}
			/>
			<SectionContainerGrid columns="3" style={{ gap: '20px' }}>
				{allArticles
					?.filter((article) => article)
					.map((article, i) => {
						return (
							<ReadMore
								key={article?.contentful_id || i}
								asCard
								title={{
									titleText: article?.thumbnailLink?.title || '',
									withBorderBottom: true,
								}}
								linkSection={{
									externalLink: article?.externalArticleUrl,
									linkTo: article?.externalArticleUrl
										? undefined
										: LinkHelper.linkTo(article as ContentfulArticleResource),
								}}
							>
								{article?.thumbnailLink?.description}
							</ReadMore>
						);
					})}
			</SectionContainerGrid>
		</SectionContainer>
	);
};

const AboutPageDetails = (props: PageContext) => {
	const loginManager = useLoginManager();
	const { data } = props?.pageContext;

	if (!data) {
		console.error('No page data for this AboutPage');
		return (
			<DefaultLayoutTemplate>
				<SectionContainer withBorderTop>
					<p className={styles.message}>This page is under maintenance. Please try again later</p>
				</SectionContainer>
			</DefaultLayoutTemplate>
		);
	}

	const {
		contentful_id,
		title,
		subtitle,
		titleBackgroundImage,
		topSectionContent,
		collapsiblePanels,
		chiefExecutiveOfficeContact: chiefExecutiveOfficerContact, // typo, missing 'r'
		boardOfDirectorsContacts,
		corporateGovernanceArticles,
		overviewHeading,
		chiefExecutiveOfficerHeading,
		livecorpBoardOfDirectorsHeading,
		corporateGovernanceHeading,
	} = data;

	const { data: staffContactsData, refetch } = useQuery<StaffContactsQueryResult>(
		StaffContactsGraphql,
		{
			variables: {
				id: contentful_id,
			},
		}
	);

	const chiefExecutiveOfficerJsx = chiefExecutiveOfficerContact ? (
		<div className={styles.chiefExecutiveOfficerContainer}>
			<div className={styles.chiefExecutiveOfficerThumbnailContainer}>
				<img
					src={chiefExecutiveOfficerContact.thumbnailImage?.fluid?.src || ''}
					className={styles.chiefExecutiveOfficerImage}
				/>
			</div>
			<ReadMore
				title={{
					titleText: chiefExecutiveOfficerContact.name || '',
					withBorderBottom: true,
				}}
				linkSection={
					chiefExecutiveOfficerContact.url
						? {
								externalLink: chiefExecutiveOfficerContact.url,
						  }
						: {
								linkTo: '/',
						  }
				}
			>
				{chiefExecutiveOfficerContact.topDescription}
			</ReadMore>
		</div>
	) : undefined;

	const getStaffContacts = () => {
		const staffContacts = staffContactsData?.contentfulStaffContacts;
		if (!staffContacts) {
			if (loginManager.isLoggedIn) {
				refetch();
			}
		}
		return (
			loginManager.isLoggedIn &&
			staffContacts?.length && (
				<SectionContainer>
					<SectionContainerTitle
						text="Staff Contacts"
						id={LinkHelper.parseInternalLink('Staff Contacts')}
					/>
					<SectionContainerGrid columns="2">
						{staffContacts
							?.filter((contact: any) => contact)
							.map((contact, i) => {
								return (
									<div className={styles.contactCardWrapper} key={`${contact?.contentful_id}_${i}`}>
										<div className={styles.contactCardTitle}>{contact?.name}</div>
										<div>
											{contact?.email && <p>Email: {contact?.email}</p>}
											{contact?.phone && <p>Phone: {contact?.phone}</p>}
											{contact?.fax && <p>Fax: {contact?.fax}</p>}
										</div>
										{contact?.responsibilities && (
											<div>
												<div className={styles.contactCardSubtitle}>RESPONSIBILITIES</div>
												<ul className={styles.responsibilities}>
													{contact?.responsibilities
														.filter((r) => r)
														.map((responsibility, i) => {
															return <li key={`${responsibility}_${i}`}>{responsibility}</li>;
														})}
												</ul>
											</div>
										)}
									</div>
								);
							})}
					</SectionContainerGrid>
				</SectionContainer>
			)
		);
	};

	const directorsContactsJsx = (
		<SectionContainer>
			<SectionContainerTitle
				text={livecorpBoardOfDirectorsHeading || defaultAboutPageSections.boardOfDirectors}
				id={LinkHelper.parseInternalLink(
					livecorpBoardOfDirectorsHeading || defaultAboutPageSections.boardOfDirectors
				)}
			/>
			<SectionContainerGrid columns="2" style={{ gap: '80px 100px' }}>
				{boardOfDirectorsContacts
					?.filter((contact) => contact)
					.map((contact, i) => {
						return (
							<ReadMore
								key={`${contact?.name}_${i}`}
								title={{
									leftImage: contact?.thumbnailImage?.fluid?.src || undefined,
									titleText: contact?.name || '',
									subTitle: contact?.rolePosition || undefined,
									withBorderBottom: true,
								}}
								linkSection={
									contact?.url
										? {
												externalLink: contact?.url,
										  }
										: {
												linkTo: '/',
										  }
								}
							>
								{contact?.topDescription}
							</ReadMore>
						);
					})}
			</SectionContainerGrid>
		</SectionContainer>
	);

	return (
		<DefaultLayoutTemplate onLoginSuccess={refetch}>
			<div>
				<Helmet>
					<title>{curatedTitle(title)}</title>
				</Helmet>
				<MainImage
					backgroundImageSrc={getHrImageUrl(titleBackgroundImage?.file?.url)}
					header={title}
					paragraph={subtitle}
					imageContainerClassName={styles.mainImage}
					gradientClassName={styles.gradient}
				/>
				<SectionContainer isGrey className={styles.sectionContainerTop}>
					<SectionContainerTitle
						text={overviewHeading || defaultAboutPageSections.overview}
						id={LinkHelper.parseInternalLink(overviewHeading || defaultAboutPageSections.overview)}
					/>
					<div>{getRichText(topSectionContent, defaultRichTextOptions)}</div>
				</SectionContainer>
				<SectionContainer isGrey>
					<CollapsiblePanelCollection
						parentId={LinkHelper.parseInternalLink(
							overviewHeading || defaultAboutPageSections.overview
						)}
						panelsCollection={collapsiblePanels}
					/>
				</SectionContainer>
				{chiefExecutiveOfficerContact && (
					<SectionContainer className={styles.chiefExecutiveOfficerSection}>
						<SectionContainerTitle
							text={chiefExecutiveOfficerHeading || defaultAboutPageSections.chiefExecutiveOfficer}
							id={LinkHelper.parseInternalLink(
								chiefExecutiveOfficerHeading || defaultAboutPageSections.chiefExecutiveOfficer
							)}
						/>
						{chiefExecutiveOfficerJsx}
					</SectionContainer>
				)}
				{getStaffContacts()}
				{boardOfDirectorsContacts && directorsContactsJsx}
				{corporateGovernanceArticles && contentful_id && (
					<GovernanceArticles
						corporateGovernanceHeading={corporateGovernanceHeading}
						corporateGovernanceArticles={corporateGovernanceArticles}
						pageContentfulId={contentful_id}
					/>
				)}
			</div>
		</DefaultLayoutTemplate>
	);
};

export default class AboutPage extends React.Component<PageContext> {
	render() {
		return <AboutPageDetails {...this.props} />;
	}
}
